"use client";

import Link from "next/link";
import Image from "next/image";
import Badge from "@/components/Badge";
import Submenu from "@/components/Submenu";
import {
  BiBook,
  BiHeart,
  BiSearch,
  BiShoppingBag,
  BiUser,
} from "react-icons/bi";

import { useRecoilValue } from "recoil";
import { cartState, userState, wishlistState } from "@/@stores/stores";

import styles from "./Header.module.scss";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useMemo } from "react";
import { CartItem } from "@/@types";

import OutsideClickHandler from "react-outside-click-handler";

interface Props {
  categories: any;
}

export default function Header(props: Props) {
  const { categories } = props;
  const cart = useRecoilValue(cartState);
  const user = useRecoilValue(userState);
  const wishlist = useRecoilValue(wishlistState);
  const [menuActive, setMenuActive] = useState(false);
  const [smallLogo, setSmallLogo] = useState(false);

  const countCartItems = useMemo(() => {
    return cart.reduce((acc: number, item: CartItem) => {
      return acc + item.quantity;
    }, 0);
  }, [cart]);

  function handleScroll() {
    const scrollY = window.scrollY;
    if (scrollY > 42 && !smallLogo) {
      setSmallLogo(true);
    } else {
      setSmallLogo(false);
    }
  }

  // add class is pinned to headerRef if scrollY > 20
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log("logo");
  }, [smallLogo]);

  return (
    <>
      <div className={styles.navigation}>
        <div className={styles.navigation__container}>
          <nav>
            <Link prefetch={false} href="/dfilipa" className={styles.link}>
              D.Filipa
            </Link>

            <Link prefetch={false} href="/kosher" className={styles.link}>
              Kosher
            </Link>
            <Link prefetch={false} href="/transforma" className={styles.link}>
              Transforma
            </Link>
            <Link prefetch={false} href="/sazonal" className={styles.link}>
              Sazonal
            </Link>
          </nav>
          <nav>
            {/* <Link prefetch={false} 
              href="https://instagram.com/dfilipa"
              target="_blank"
              className={styles.link}
              
            >
              <BiLogoInstagram size={16} /> DFILIPA
            </Link>
            <Link prefetch={false} 
              href="https://facebook.com/dfilipalocacao"
              target="_blank"
              className={styles.link}
              
            >
              <BiLogoFacebookCircle size={16} /> DFILIPALOCACAO
            </Link> */}

            {!user?.uuid && (
              <Link
                prefetch={false}
                href="/cadastro"
                className={styles.link}
                title="Cadastre-se"
              >
                {/* <LuUser /> */}
                <strong
                  style={{
                    fontWeight: 700,
                    // backgroundColor: "var(--primary)",
                    // color: "#fff",
                    // padding: "0.25rem 0.75rem",
                    // borderRadius: 30,
                    lineHeight: "normal",
                    gap: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Cadastre-se
                </strong>
              </Link>
            )}
          </nav>
        </div>
      </div>

      <header className={styles.header}>
        <Link
          prefetch={false}
          href="/"
          className={styles.logo}
          style={{
            height: smallLogo ? 80 : 120,
          }}
        >
          <motion.div whileTap={{ scale: 0.95 }}>
            {!smallLogo && (
              <Image
                width={76}
                height={80}
                alt="D.Filipa"
                src="/images/logo.png"
                priority
              />
            )}
            <AnimatePresence>
              {smallLogo && (
                <motion.div
                  initial={{ opacity: 0, y: -30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <Image
                    width={58}
                    height={58}
                    alt="D.Filipa"
                    src="/images/logo-small.png"
                    priority
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </Link>

        <div className={styles.header__container}>
          <div className={styles.brand}>
            <nav>
              <OutsideClickHandler onOutsideClick={() => setMenuActive(false)}>
                <motion.div whileTap={{ scale: 0.95 }}>
                  <Link
                    prefetch={false}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();

                      setMenuActive(!menuActive);
                    }}
                    className={styles.button}
                    title="Catálogo"
                  >
                    <BiBook size={16} />
                    Catálogo
                  </Link>
                </motion.div>
                <Submenu
                  menuActive={menuActive}
                  categories={categories}
                  setMenuActive={setMenuActive}
                />
              </OutsideClickHandler>
              <motion.div whileTap={{ scale: 0.95 }}>
                <Link
                  prefetch={false}
                  href="/produtos/novidades"
                  className={`${styles.button} ${styles.button__text}`}
                  title="Novidades"
                >
                  {/* <LuSparkles size={16} /> */}
                  Novidades
                </Link>
              </motion.div>
              <motion.div whileTap={{ scale: 0.95 }}>
                <Link
                  prefetch={false}
                  href="/produtos/exclusivos"
                  className={`${styles.button} ${styles.button__text}`}
                  title="Exclusivos"
                >
                  {/* <LuDiamond size={16} /> */}
                  Exclusivos
                </Link>
              </motion.div>
            </nav>
          </div>

          <div className={styles.buttonGroup}>
            <motion.div whileTap={{ scale: 0.95 }}>
              <Link
                prefetch={false}
                href="/checkout"
                className={styles.buttonCircle}
                title="Carrinho"
              >
                <Badge color="#FF9900" value={countCartItems} />
                <BiShoppingBag size={16} />
              </Link>
            </motion.div>

            <motion.div whileTap={{ scale: 0.95 }}>
              <Link
                prefetch={false}
                href="/wishlist"
                className={styles.buttonCircle}
                title="Wishlist"
              >
                <Badge color="#FF9900" value={wishlist.length} />
                <BiHeart size={16} />
              </Link>
            </motion.div>
            <motion.div whileTap={{ scale: 0.95 }}>
              <Link
                prefetch={false}
                href="/busca"
                className={styles.buttonCircle}
                title="Buscar"
              >
                <BiSearch size={16} />
              </Link>
            </motion.div>
            <motion.div whileTap={{ scale: 0.95 }}>
              <Link
                prefetch={false}
                href="/minha-conta"
                className={styles.buttonGretting}
                title="Minha conta"
              >
                <BiUser size={16} />
                {user?.uuid && (
                  <div className={styles.buttonGretting__info}>
                    <h5>Olá,</h5>
                    <p>{user?.name?.split(" ")[0]}</p>
                  </div>
                )}
              </Link>
            </motion.div>
          </div>
        </div>
      </header>
    </>
  );
}
