"use client";

import Link from "next/link";
import styles from "./HeaderMobile.module.scss";
import {
  BiHeart,
  BiMenu,
  BiSearch,
  BiShoppingBag,
  BiUser,
  BiX,
} from "react-icons/bi";
import Badge from "../Badge";
import { cartState, userState, wishlistState } from "@/@stores/stores";
import { CartItem } from "@/@types";
import { useState, useMemo } from "react";
import { useRecoilValue } from "recoil";

interface Props {
  categories: any;
}

export default function HeaderMobile(props: Props) {
  const { categories } = props;

  const cart = useRecoilValue(cartState);
  const wishlist = useRecoilValue(wishlistState);
  const user = useRecoilValue(userState);

  const [open, setOpen] = useState(false);

  const countCartItems = useMemo(() => {
    return cart.reduce((acc: number, item: CartItem) => {
      return acc + item.quantity;
    }, 0);
  }, [cart]);

  return (
    <div className={styles.header}>
      <div className={styles.buttons}>
        <div className={styles.logo}>
          <Link prefetch={false} href="/" className={styles.logo}>
            <img src="/images/logo-small.png" alt="D.Filipa" />
            {process.env.NEXT_PUBLIC_SHOWROOM === "true" && (
              <span className={styles.showroom}>Showroom</span>
            )}
          </Link>
        </div>
      </div>

      <div className={styles.buttons}>
        <Link
          prefetch={false}
          href="/checkout"
          className={styles.button}
          title="Carrinho"
        >
          <Badge color="#FF9900" value={countCartItems} />
          <BiShoppingBag size={20} />
        </Link>

        <Link
          prefetch={false}
          href="/wishlist"
          className={styles.button}
          title="Wishlist"
        >
          <Badge color="#FF9900" value={wishlist.length} />
          <BiHeart size={20} />
        </Link>

        <Link
          prefetch={false}
          href="/minha-conta"
          className={styles.buttonGretting}
          title="Minha conta"
        >
          <BiUser size={20} />
          {process.env.NEXT_PUBLIC_SHOWROOM === "true" && user?.uuid && (
            <div className={styles.buttonGretting__info}>
              <h5>Olá,</h5>
              <p>{user?.name?.split(" ")[0]}</p>
            </div>
          )}
        </Link>

        <div
          className={styles.button}
          title="Menu"
          onClick={() => setOpen(!open)}
        >
          {open ? <BiX size={20} /> : <BiMenu size={20} />}
        </div>
      </div>

      {open && (
        <div className={styles.menu} onClick={() => setOpen(false)}>
          <Link
            prefetch={false}
            href="/busca"
            className={styles.busca}
            title="Busca de produtos"
          >
            <span>Busca de produtos</span>
            <BiSearch size={20} />
          </Link>

          <div className={styles.menu__item}>Catálogo</div>

          <div className={styles.menu__categories}>
            {categories.map((category: any) => (
              <Link
                prefetch={false}
                href={`/produtos/${category.slug}`}
                key={category.uuid}
              >
                {category.name}
              </Link>
            ))}
          </div>

          <div className={styles.menu__item}>Menu</div>
          <div className={styles.menu__categories}>
            <div className={styles.menu__small}>
              <Link prefetch={false} href="/produtos/novidades">
                Novidades
              </Link>
            </div>

            <div className={styles.menu__small}>
              <Link prefetch={false} href="/produtos/exclusivos">
                Exclusivos
              </Link>
            </div>

            <div className={styles.menu__small}>
              <Link prefetch={false} href="/dfilipa">
                D.Filipa
              </Link>
            </div>

            <div className={styles.menu__small}>
              <Link prefetch={false} href="/kosher">
                Kosher
              </Link>
            </div>

            <div className={styles.menu__small}>
              <Link prefetch={false} href="/transforma">
                Transforma
              </Link>
            </div>

            <div className={styles.menu__small}>
              <Link prefetch={false} href="/sazonal">
                Sazonal
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
